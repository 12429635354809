
import { defineComponent, ref, onMounted } from "vue";
import KTDatatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "kt-subscription-list",
  components: {
    KTDatatable,
  },
  setup() {
    const data = ref([
      {
        customer: "Emma Smith",
        status: "Active",
        color: "success",
        billing: "Auto-debit",
        product: "Basic",
        createdDate: "Oct 25, 2021",
      },
      {
        customer: "Melody Macy",
        status: "Active",
        color: "success",
        billing: "Manual - Credit Card",
        product: "Basic",
        createdDate: "Mar 10, 2021",
      },
      {
        customer: "Max Smith",
        status: "Active",
        color: "primary",
        billing: "Manual - Cash",
        product: "Teams Bundle",
        createdDate: "Jul 25, 2021",
      },
      {
        customer: "Sean Bean",
        status: "Expiring",
        color: "warning",
        billing: "Manual - Paypal",
        product: "Enterprise",
        createdDate: "Aug 19, 2021",
      },
      {
        customer: "Brian Cox",
        status: "Expiring",
        color: "warning",
        billing: "Auto-debit",
        product: "Basic",
        createdDate: "May 05, 2021",
      },
      {
        customer: "Mikaela Collins",
        status: "Active",
        color: "success",
        billing: "Auto-debit",
        product: "Enterprise Bundle",
        createdDate: "Aug 19, 2021",
      },
      {
        customer: "Francis Mitcham",
        status: "Active",
        color: "success",
        billing: "Auto-debit",
        product: "Teams",
        createdDate: "Jun 20, 2021",
      },
      {
        customer: "Olivia Wild",
        status: "Suspended",
        color: "danger",
        billing: "--",
        product: "Enterprise",
        createdDate: "Jun 24, 2021",
      },
      {
        customer: "Neil Owen",
        status: "Expiring",
        color: "warning",
        billing: "Auto-debit",
        product: "Basic",
        createdDate: "Aug 19, 2021",
      },
      {
        customer: "Dan Wilson",
        status: "Active",
        color: "success",
        billing: "Auto-debit",
        product: "Enterprise Bundle",
        createdDate: "Feb 21, 2021",
      },
      {
        customer: "Emma Bold",
        status: "Active",
        color: "success",
        billing: "Manual - Credit Card",
        product: "Enterprise",
        createdDate: "May 05, 2021",
      },
      {
        customer: "Ana Crown",
        status: "Active",
        color: "success",
        billing: "Manual - Credit Card",
        product: "Basic",
        createdDate: "Jun 24, 2021",
      },
      {
        customer: "Robert Doe",
        status: "Suspended",
        color: "danger",
        billing: "--",
        product: "Teams Bundle",
        createdDate: "Jul 25, 2021",
      },
      {
        customer: "John Miller",
        status: "Active",
        color: "success",
        billing: "Manual - Paypal",
        product: "Enterprise",
        createdDate: "Sep 22, 2021",
      },
      {
        customer: "Lucy Kunic",
        status: "Active",
        color: "success",
        billing: "Manual - Credit Card",
        product: "Basic",
        createdDate: "Nov 10, 2021",
      },
      {
        customer: "Neil Owen",
        status: "Suspended",
        color: "danger",
        billing: "--",
        product: "Basic Bundle",
        createdDate: "Jun 20, 2021",
      },
      {
        customer: "Dan Wilson",
        status: "Expiring",
        color: "warning",
        billing: "Manual - Paypal",
        product: "Enterprise",
        createdDate: "May 05, 2021",
      },
      {
        customer: "Emma Smith",
        status: "Active",
        color: "success",
        billing: "Auto-debit",
        product: "Teams",
        createdDate: "Apr 15, 2021",
      },
      {
        customer: "Melody Macy",
        status: "Active",
        color: "success",
        billing: "Manual - Credit Card",
        product: "Basic",
        createdDate: "Oct 25, 2021",
      },
      {
        customer: "Max Smith",
        status: "Suspended",
        color: "danger",
        billing: "--",
        product: "Basic Bundle",
        createdDate: "Feb 21, 2021",
      },
    ]);
    const headerConfig = ref([
      {
        key: "checkbox",
        sortable: false,
      },
      {
        name: "Customer",
        key: "customer",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        sortable: true,
      },
      {
        name: "Billing",
        key: "billing",
        sortable: true,
      },
      {
        name: "Product",
        key: "product",
        sortable: true,
      },
      {
        name: "Created Date",
        key: "createdDate",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Subscription List", ["Apps", "Subscriptions"]);
    });

    return {
      data,
      headerConfig,
    };
  },
});
